import React, { useEffect, useState } from 'react';
import { Typography, Box, MenuItem, Menu, IconButton, TableCell, TableRow, TableBody, Table, TableHead, InputLabel, Select, Tooltip, CircularProgress, Chip } from '@mui/material';
import { fetchTeamsCollaborators } from 'src/store/actions/teamCollaboratorsActions';
import useAxios from 'src/services/apiInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { setPerPage } from 'src/store/actions/teamCollaboratorsActions';
import { setCurrentPage } from 'src/store/actions/teamCollaboratorsActions';
import { API_URL } from 'src/config';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { CustomTablePagination } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import * as XLSX from "xlsx";
import { FilterListFormControl } from 'src/styles/CustomStyles';
import { setSortOrder } from 'src/store/actions/teamCollaboratorsActions';
import { FilterButton } from 'src/styles/CustomStyles';
import { setSortBy } from 'src/store/actions/teamCollaboratorsActions';
import { setFilters } from 'src/store/actions/teamCollaboratorsActions';
import { FiltersTextField } from 'src/styles/CustomStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertchartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { fetchAllTeams } from 'src/store/actions/teamActions';
import CollaboratorEditModal from './CollaboratorsModals/CollaboratorEditModal';
import DeleteCollaboratorModal from './CollaboratorsModals/DeleteCollaboratorModal';
import { Groups2Outlined, LockOpen, LockPerson, PasswordOutlined, PersonOffOutlined, RefreshOutlined } from '@mui/icons-material';
import SetCollaboratorStatus from './Modals/SetCollaboratorStatus';
import RegeneratePassword from './Modals/RegeneratePassword';
import ShowCollaboratorSurveys from './Modals/ShowCollaboratorsSurveys';

const exportToExcel = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Liste des  Collaborateurs');
    XLSX.writeFile(wb, 'liste_des_collaborateurs.xlsx');
};
  


const CollaboratorList = () => {
    const dispatch = useDispatch();
    const {
      collaborators,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.collaborators);
    // eslint-disable-next-line no-unused-vars
    const { allTeams = [], loading: teamsLoading, error: teamsError  } = useSelector(state => state.teams);

    useEffect(() => {
      dispatch(fetchAllTeams());
    }, [dispatch]);

    const [page, setPage] = useState(currentPage - 1);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    
    const [errors, setErrors] = useState({});
    const api = useAxios();
    const [loadingSubmit, setLoadingSubmit] = useState(false); // Add loading state
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [surveysModalOpen, setSurveysModalOpen] = useState(false);
    
    useEffect(() => {
        dispatch(fetchTeamsCollaborators(page + 1));
        console.log(collaborators)
    }, [dispatch, filters, sortBy, sortOrder, rowsPerPage, page]);
  
    useEffect(() => {
      if (page >= totalPages && totalPages > 0) {
        setPage(totalPages - 1);
      }
    }, [totalPages, page]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      dispatch(setCurrentPage(newPage + 1));
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      dispatch(setPerPage(parseInt(event.target.value, 10)));
      setPage(0);
    };

     const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  
    const handleEditClick = (team) => {
          console.log(team)
        setSelectedCollaborator(team);
        setEditModalOpen(true);
        setAnchorEl(null);
    };
  
    const handleEditModalClose = () => {
      setEditModalOpen(false);
      setSelectedCollaborator(null);
    };
   
     const handleEditSubmit = async () => {
        setLoadingSubmit(true); // Start loading
        // Reset errors
      
        setErrors({});
      try {
          const updateData = {
              //team_id: selectedCollaborator.team_id,
              first_name: selectedCollaborator.first_name,
              last_name: selectedCollaborator.last_name,
              email: selectedCollaborator.email,
              company: selectedCollaborator.company,
              team_id: selectedCollaborator.team_id,
              account_manager_id: selectedCollaborator.account_manager_id
          };

          await api.put(`${API_URL}/teams/collaborator/${selectedCollaborator.id}`, updateData);
          dispatch(fetchTeamsCollaborators(page + 1)); // Refresh teams list
          dispatch(showSnackbar(' Collaborateur id: '+selectedCollaborator.id+" "+selectedCollaborator.first_name +" mis à jour avec succés ", 'success'));

          handleEditModalClose(); // Close the modal
        } catch (error) {
          console.error("Failed to update collaborator:", error);
          if (error?.response?.data) {
            const validationErrors = error.response.data || {}; // Adjust based on your API response structure
            setErrors(validationErrors);
            dispatch(showSnackbar('Échec de la mise à jour du collaborateur : '+selectedCollaborator.id+" : "+selectedCollaborator.first_name+" "+selectedCollaborator.last_name, 'error'));
          } else {
              dispatch(showSnackbar('Échec de la mise à jour de l\'équipe id: '+selectedCollaborator.id+" "+selectedCollaborator.first_name+" "+selectedCollaborator.last_name+' '+ error.message , 'error'));
          }
      } finally {
        setLoadingSubmit(false); // Start loading
      }
     
    }; 
  
    const handleDeleteClick = (user) => {
      setSelectedCollaborator(user);
      setDeleteModalOpen(true);
      setAnchorEl(null);
    };
  
    const handleDeleteModalClose = () => {
      setDeleteModalOpen(false);
      setSelectedCollaborator(null);
    };
  
    const handleDeleteSubmit = async () => {
      setLoadingSubmit(true);
      try {
        await api.delete(`${API_URL}/teams/collaborator/${selectedCollaborator.id}`);
        dispatch(fetchTeamsCollaborators());
          handleDeleteModalClose();
        dispatch(showSnackbar('Collaborateur supprimé avec succés', 'success'));
          
      } catch (error) {
        console.error("Failed to delete team:", error);
        dispatch(showSnackbar('Échec de la suppression de l\'Collaborateur', 'error'));
      } finally {
        setLoadingSubmit(false);
      }
    };
  
    const handleClearFilters = () => {
        dispatch(setFilters({}));
        dispatch(setSortBy('id'));
        dispatch(setSortOrder('desc'));
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event, user) => {
      setSelectedCollaborator(user);
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCollaborator(null);
  
    };
    
   
    // Feature 1: Update Password
    const handleRegeneratePasswordClick = async () => {
        setPasswordModalOpen(true);
        setAnchorEl(null);
    };

    // Feature 2: Set Collaborator Status Modal
    const handleSetStatusClick = () => {
        setStatusModalOpen(true);
        setAnchorEl(null);
    };

     // Feature 3: Get Collaborator Surveys
     const handleGetCollabratorSurveysClick = () => {
        setSurveysModalOpen(true);
        setAnchorEl(null);
    };

    
   
  
  return (
    <Box>
       {/* Teams Table */}
       <Box>
        <Box mb={2} style={{ display: "flex", flexWrap:"wrap", justifyContent: "space-between", alignItems: "center" }}>
           {/*  <Typography variant="h6" gutterBottom color="textPrimary">
                Liste des Collaborateurs
            </Typography> */}

            <Box display="flex" flexWrap="wrap" gap={2}>
                {/* First Name Filter */}
                <FiltersTextField
                    label="Prénom"
                    variant="outlined"
                    size="small"
                    value={filters.first_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, first_name: e.target.value }))}
                />

                {/* Last Name Filter */}
                <FiltersTextField
                    label="Nom de famille"
                    variant="outlined"
                    size="small"
                    value={filters.last_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, last_name: e.target.value }))}
                />

                {/* Last Name Filter */}
                <FiltersTextField
                    label="Entreprise"
                    variant="outlined"
                    size="small"
                    value={filters.company || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, company: e.target.value }))}
                />

                {/* Team Filter */}
                <FilterListFormControl size="small">
                    <InputLabel>Equipes</InputLabel>
                    <Select
                    label="Equipes"
                    value={filters.team_id || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, team_id: e.target.value }))}
                    >
                    {allTeams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                        {team.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FilterListFormControl>

                {/* Sorting Options */}
                <FilterListFormControl size="small">
                    <InputLabel>Tri Par</InputLabel>
                    <Select
                    label="Tri par"
                    value={sortBy}
                    onChange={(e) => dispatch(setSortBy(e.target.value))}
                    >
                    <MenuItem value="id">ID</MenuItem>
                    <MenuItem value="first_name">Prénom</MenuItem>
                    <MenuItem value="last_name">Nom de famille</MenuItem>
                    <MenuItem value="team.name">Equipe</MenuItem>
                    <MenuItem value="created_at">Créé Le</MenuItem>
                    </Select>
                </FilterListFormControl>

                {/* Sorting Order */}
                <FilterListFormControl size="small">
                    <InputLabel>Ordre de Tri</InputLabel>
                    <Select
                    label="Order de tri"
                    value={sortOrder}
                    onChange={(e) => dispatch(setSortOrder(e.target.value))}
                    >
                    <MenuItem value="asc">Ascendant</MenuItem>
                    <MenuItem value="desc">Descendant</MenuItem>
                    </Select>
                </FilterListFormControl>
                
                <Tooltip title="Rafraichir et vider les filtres">
                    <IconButton onClick={handleClearFilters} color="primary">
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
                {/* Export Button */}
                <Tooltip title="Exporter sous format Excel">

                    <FilterButton
                        variant="contained"
                        color="primary"
                        startIcon={<FileDownloadOutlinedIcon />}
                        onClick={() => exportToExcel(collaborators)}
                    >
                        Exporter
                    </FilterButton>
                </Tooltip>
            </Box>

        </Box>

        {/* Table Container */}
        <Box sx={{ flexGrow: 1, overflowX: 'auto', mt: 2 }}>
          <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell className="center-align">ID</TableCell>
                <TableCell className="center-align">Prénom</TableCell>
                <TableCell className="center-align">Nom</TableCell>
                <TableCell className="center-align">Entreprise</TableCell>
                <TableCell className="center-align">Equipe</TableCell>
                <TableCell className="center-align">Chargé de compte</TableCell>
                <TableCell className="center-align">Etat</TableCell>
                <TableCell className="center-align">Crée le</TableCell>
                <TableCell className="center-align">Modifié le</TableCell>
                <TableCell className="center-align">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={9} align="center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={9} align="center">
                    <Typography>Error: {error}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                collaborators.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="center-align">{user.id}</TableCell>
                    <TableCell className="center-align">{user.first_name}</TableCell>
                    <TableCell className="center-align">{user.last_name}</TableCell>
                    <TableCell className="center-align">{user.company || "Non définie"}</TableCell>
                    <TableCell className="center-align">{user.team.name ? user.team.name : "Non assigné"}</TableCell>
                    <TableCell className="center-align">{user.account_manager.name ? user.account_manager.name : "Non assigné"}</TableCell>
                    <TableCell className="center-align">
                        <Chip 
                            sx={{ 
                                color: "#fff",
                                height:"24px",
                                backgroundColor: user.status === "active" || user.status === null  ? 
                                    Colors.GREEN :
                                    Colors.PAS_DU_TOUT
                            }}
                            label={
                                user.status === "active" || user.status === null  ?  
                                "Actif" :
                                "Inactif"
                            }
                        />
                    </TableCell>
                    <TableCell className="center-align">
                        {new Date(user.created_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                      <TableCell className="center-align">
                        {new Date(user.updated_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                    <TableCell sx={{ position: 'sticky', right: 0, background: '#fff' }}>
                        <Tooltip title="Afficher les diagnostics en cours">
                            <IconButton sx={{ color: Colors.MEDIUM_GREEN }} onClick={handleGetCollabratorSurveysClick}>
                            <InsertchartOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mettre à jour les données">
                            <IconButton color="default" onClick={() => handleEditClick(user)}>
                                <EditOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer l'Collaborateur">
                            <IconButton sx={{ color: "#ff0000"}} onClick={() => handleDeleteClick(user)}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Plus d'options">
                            <IconButton
                                onClick={(event) => handleMenuClick(event, user)}
                            >
                                <MoreVertOutlinedIcon />
                            </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                          sx: {
                            boxShadow: 1,
                            width: "240px",
                          },
                        }}
                      >
                        <MenuItem onClick={handleRegeneratePasswordClick}>
                            {/*  {loadingSubmit ? (
                                <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                            ) : ( */}
                            <PasswordOutlined sx={{ color: Colors.GREEN, mr:2 }} />
                            {/* )} */}
                            Regénérer le mot de passe et renvoyer par email
                                </MenuItem>
                                <MenuItem onClick={handleSetStatusClick}>
                                      {loadingSubmit ? (
                                            <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                                        ) : (selectedCollaborator?.status === null || selectedCollaborator?.status === 'active') ?
                                            <LockPerson sx={{ color: Colors.GREEN, mr:2 }}/>
                                            :
                                            <LockOpen sx={{ color: Colors.GREEN, mr:2 }} /> 
                                        }
                                        {(selectedCollaborator?.status === null || selectedCollaborator?.status === 'active') ? 'Désactiver' : 'Activer'} le compte

                                </MenuItem>
                      </Menu>

                   
                  </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
               
        </Box>

        {/* Pagination */}
        <CustomTablePagination
          rowsPerPageOptions={[10, 25,50,100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Afficher"
          nextIconButtonProps={{
            'aria-label': 'Page suivante',
          }}
          backIconButtonProps={{
            'aria-label': 'Page précédente',
          }}
        />
      </Box>

       
      
      {/* Edit Team Modal */}
      {selectedCollaborator && (
        <CollaboratorEditModal
          open={editModalOpen}
          onClose={handleEditModalClose}
          onSubmit={handleEditSubmit}
          collaboratorData={selectedCollaborator}
          setCollaboratorData={setSelectedCollaborator}
          loading={loadingSubmit} // Pass loading state
          errors={errors} // Pass any validation errors here
        />
      )}
       {/* Delete Team Modal */}
       {selectedCollaborator && (
        <DeleteCollaboratorModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          onSubmit={handleDeleteSubmit}
          collaboratorData={selectedCollaborator}
          loading={loadingSubmit}
        />
      )}

    {statusModalOpen && (
        <SetCollaboratorStatus 
            collaborator={selectedCollaborator} 
            onClose={() => setStatusModalOpen(false)} 
            onStatusChange={() => {
                //setSelectedCollaborator({ ...selectedCollaborator, status: newStatus });
                dispatch(fetchTeamsCollaborators())
            }} 
        />
    )}
     {passwordModalOpen && (
        <RegeneratePassword 
            collaborator={selectedCollaborator} 
            onClose={() => setPasswordModalOpen(false)} 
            onPasswordRegenerated={() => {
                //setSelectedCollaborator({ ...selectedCollaborator, status: newStatus });
                dispatch(fetchTeamsCollaborators())
            }} 
        />
    )}
     {surveysModalOpen && (
        <ShowCollaboratorSurveys 
            collaborator={selectedCollaborator} 
            onClose={() => setSurveysModalOpen(false)} 
        />
    )}
    </Box>
  );
};

export default CollaboratorList;
